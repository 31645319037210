import React, { useState, useEffect } from "react";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  FaGamepad,
  FaGhost,
  FaDice,
  FaPuzzlePiece,
  FaIcons,
  FaHome,
} from "react-icons/fa";
import Training from "./components/Training";
import AITraining from "./components/AITraining";
import Home from "./components/Home";
import "./App.css";

function App() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
          <div className="nav-content">
            <Link to="/">
              <FaHome
                style={{ fontSize: "1rem" }}
                className={`nav-icon ${isScrolled ? "hidden" : "visible"}`}
              />
              <img
                src="/assets/qiiver_logo.png"
                alt="Qiiver Logo"
                className={`nav-logo ${isScrolled ? "visible" : ""}`}
              />
            </Link>

            <div className="nav-links">
              <Link to="/ai-training" className="nav-link">
                AI Training
              </Link>
            </div>
          </div>
        </nav>

        <Routes>
          <Route path="/training" element={<Training />} />
          <Route path="/ai-training" element={<AITraining />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
      <footer className="App-footer">
        <div className="content-container">
          <p>Qiiver 2025</p>
        </div>
      </footer>
    </Router>
  );
}

export default App;
