import React from 'react';
import { motion } from 'framer-motion';

function Training() {
  return (
    <div className="training-page">
      <header className="training-header">
        <div className="content-container">
          <h1 className="pixel-text">Training Sessions</h1>
          <p>Level up your team's AI capabilities with our expert-led training programs</p>
        </div>
      </header>

      <section className="training-section">
        <div className="content-container">
          <div className="training-grid">
            <motion.div 
              className="training-card"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <div className="training-card-header">
                <h3>3 Hour Session</h3>
                <div className="price">$999</div>
              </div>
              <div className="training-card-content">
                <ul>
                  <li>Up to 12 employees</li>
                  <li>Interactive learning</li>
                  <li>Hands-on exercises</li>
                  <li>Q&A session</li>
                  <li>Basic AI concepts</li>
                  <li>Practical applications</li>
                </ul>
                <button className="pixel-button">
                  <span className="button-text">BOOK 2HR SESSION</span>
                  <span className="button-border"></span>
                </button>
              </div>
            </motion.div>
            
            <motion.div 
              className="training-card"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <div className="training-card-header">
                <h3>6 Hour Session</h3>
                <div className="price">$1,799</div>
              </div>
              <div className="training-card-content">
                <ul>
                  <li>Up to 12 employees</li>
                  <li>Deep-dive workshops</li>
                  <li>Extended practice time</li>
                  <li>Implementation planning</li>
                  <li>Advanced AI concepts</li>
                  <li>Custom solutions workshop</li>
                </ul>
                <button className="pixel-button">
                  <span className="button-text">BOOK 4HR SESSION</span>
                  <span className="button-border"></span>
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Training;
