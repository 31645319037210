import React, { useState, useEffect, useCallback } from 'react';

const GAME_WIDTH = 600;
const GAME_HEIGHT = 400;
const ARROW_SPEED = 8;
const TARGET_SPAWN_RATE = 2000;
const ARROW_SIZE = 20;
const TARGET_SIZE = 40;
const GAME_DURATION = 30; // 30 seconds game duration

function ArrowGame({ onScoreChange }) {
  const [arrow, setArrow] = useState({ x: 30, y: GAME_HEIGHT / 2, angle: 0 });
  const [targets, setTargets] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [projectiles, setProjectiles] = useState([]);
  const [timeLeft, setTimeLeft] = useState(GAME_DURATION);
  const [arrowsShot, setArrowsShot] = useState(0);

  const spawnTarget = useCallback(() => {
    if (!isPlaying) return;
    const newTarget = {
      x: GAME_WIDTH - TARGET_SIZE,
      y: Math.random() * (GAME_HEIGHT - TARGET_SIZE),
      id: Date.now()
    };
    setTargets(prev => [...prev, newTarget]);
  }, [isPlaying]);

  const moveProjectiles = useCallback(() => {
    setProjectiles(prev => 
      prev.filter(p => {
        const inBounds = p.x < GAME_WIDTH;
        if (!inBounds) return false;
        
        // Check collision with targets
        const hit = targets.some(target => {
          const distance = Math.sqrt(
            Math.pow(p.x - target.x, 2) + 
            Math.pow(p.y - target.y, 2)
          );
          if (distance < TARGET_SIZE / 2) {
            setTargets(prev => prev.filter(t => t.id !== target.id));
            setScore(prev => {
              const newScore = prev + 1;
              onScoreChange(newScore);
              return newScore;
            });
            return true;
          }
          return false;
        });

        return !hit;
      }).map(p => ({
        ...p,
        x: p.x + ARROW_SPEED * Math.cos(p.angle),
        y: p.y + ARROW_SPEED * Math.sin(p.angle)
      }))
    );
  }, [targets, onScoreChange]);

  useEffect(() => {
    if (!isPlaying) return;
    
    const targetInterval = setInterval(spawnTarget, TARGET_SPAWN_RATE);
    const gameLoop = setInterval(moveProjectiles, 16);
    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          setGameOver(true);
          setIsPlaying(false);
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(targetInterval);
      clearInterval(gameLoop);
      clearInterval(timer);
    };
  }, [isPlaying, spawnTarget, moveProjectiles]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isPlaying) return;
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const angle = Math.atan2(y - arrow.y, x - arrow.x);
      setArrow(prev => ({ ...prev, angle }));
    };

    const handleClick = () => {
      if (!isPlaying) return;
      setProjectiles(prev => [...prev, { ...arrow }]);
      setArrowsShot(prev => prev + 1);
    };

    const gameArea = document.querySelector('.game-area');
    if (gameArea) {
      gameArea.addEventListener('mousemove', handleMouseMove);
      gameArea.addEventListener('click', handleClick);
      return () => {
        gameArea.removeEventListener('mousemove', handleMouseMove);
        gameArea.removeEventListener('click', handleClick);
      };
    }
  }, [arrow, isPlaying]);

  const resetGame = () => {
    setArrow({ x: 50, y: GAME_HEIGHT / 2, angle: 0 });
    setTargets([]);
    setProjectiles([]);
    setScore(0);
    setGameOver(false);
    setIsPlaying(false);
    setTimeLeft(GAME_DURATION);
    setArrowsShot(0);
  };

  return {
    arrow,
    targets,
    projectiles,
    isPlaying,
    gameOver,
    score,
    timeLeft,
    arrowsShot,
    accuracy: arrowsShot > 0 ? Math.round((score / arrowsShot) * 100) : 0,
    startGame: () => setIsPlaying(true),
    resetGame
  };
}

export default ArrowGame;
