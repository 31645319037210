import React from "react";
import { motion } from "framer-motion";

function AITraining() {
  return (
    <div className="ai-training-page">
      <header className="training-header">
        <div className="content-container">
          <h1 className="pixel-text">AI Training Programs</h1>
          <p>
            Comprehensive in-person, instructor-led AI training solutions to
            empower your team with cutting-edge capabilities
          </p>
        </div>
      </header>

      <section className="ai-training-content">
        <div className="content-container">
          <div className="schedule-explainer">
            <h2 className="pixel-text">Training Format</h2>
            <p>
              Our in-person AI training program is structured into two
              comprehensive instructor-led sessions that can be taken together
              or individually. Each session is carefully designed to build your
              team's AI capabilities through a mix of theory and hands-on
              workshops.
            </p>
            <div className="format-highlights">
              <div className="highlight-item">
                <span className="highlight-icon">🎯</span>
                <p>30-minute focused segments</p>
              </div>
              <div className="highlight-item">
                <span className="highlight-icon">🔄</span>
                <p>Theory followed by practical workshops</p>
              </div>
              <div className="highlight-item">
                <span className="highlight-icon">💡</span>
                <p>Interactive learning experience</p>
              </div>
            </div>
          </div>

          <div className="training-schedule">
            <h2 className="pixel-text">Training Schedule</h2>
            <div className="schedule-options">
              <motion.div
                className="schedule-card"
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <h3>Session 1: AI Foundations</h3>
                <p className="session-subtitle">
                  Master the Core Concepts (3 Hours)
                </p>
                <div className="schedule-timeline">
                  <div className="timeline-item">
                    <span className="time">30min</span>
                    <div className="item-content">
                      <h4>AI Introduction & Terms</h4>
                      <p>
                        Core AI concepts, terminology, prompting, and
                        foundational principles
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <span className="time">40min</span>
                    <div className="item-content">
                      <h4>
                        Workshop: <br />
                        Prompt Engineering
                      </h4>
                      <p>Mastering prompt design and optimization techniques</p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <span className="time">20min</span>
                    <div className="item-content">
                      <h4>Implementation</h4>
                      <p>Apply prompts to your specific use cases</p>
                    </div>
                  </div>
                  <div className="timeline-item break">
                    <div className="item-content">
                      <h4>Break</h4>
                      <p>Refresh and recharge</p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <span className="time">20min</span>
                    <div className="item-content">
                      <h4>AI Applications</h4>
                      <p>
                        Exploring practical AI implementations and use cases
                      </p>
                    </div>
                  </div>

                  <div className="timeline-item">
                    <span className="time">40min</span>
                    <div className="item-content">
                      <h4>
                        Workshop: <br /> AI Automation
                      </h4>
                      <p>
                        Building and deploying custom AI workflows for
                        automation
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <span className="time">20min</span>
                    <div className="item-content">
                      <h4>Implementation</h4>
                      <p>Plan AI agent integration for your workflows</p>
                    </div>
                  </div>
                </div>
              </motion.div>

              <motion.div
                className="schedule-card"
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <h3>Session 2: Advanced AI Concepts</h3>
                <p className="session-subtitle">
                  Deep Dive Implementation (3 Hours)
                </p>
                <div className="schedule-timeline">
                  <div className="timeline-item">
                    <span className="time">30min</span>
                    <div className="item-content">
                      <h4>Advanced AI Concepts</h4>
                      <p>
                        Deep dive into advanced AI architectures and
                        methodologies and vectorization
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <span className="time">40min</span>
                    <div className="item-content">
                      <h4>
                        Workshop: <br /> AI Agents
                      </h4>
                      <p>
                        Building sophisticated AI agents with advanced
                        capabilities
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <span className="time">20min</span>
                    <div className="item-content">
                      <h4>Implementation</h4>
                      <p>Architect advanced agent solutions for your needs</p>
                    </div>
                  </div>
                  <div className="timeline-item break">
                    <div className="item-content">
                      <h4>Break</h4>
                      <p>Refresh and recharge</p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <span className="time">20min</span>
                    <div className="item-content">
                      <h4>RAG Systems</h4>
                      <p>
                        Advanced RAG implementation and knowledge integration
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <span className="time">40min</span>
                    <div className="item-content">
                      <h4>
                        Workshop:
                        <br /> knowledge Base Integration
                      </h4>
                      <p>
                        Advanced RAG implementation with knowledge base
                        integration
                      </p>
                    </div>
                  </div>
                  <div className="timeline-item">
                    <span className="time">20min</span>
                    <div className="item-content">
                      <h4>Implementation</h4>
                      <p>Design RAG supported AI agents for your business</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>

          <div className="training-benefits">
            <h2 className="pixel-text">Why Choose Our Training?</h2>
            <div className="benefits-grid">
              <div className="benefit-item">
                <span className="benefit-icon">👥</span>
                <h3>Expert Instructor</h3>
                <p>Learn in-person from an experienced AI instructor</p>
              </div>
              <div className="benefit-item">
                <span className="benefit-icon">💡</span>
                <h3>Hands-on Learning</h3>
                <p>Practice with real-world scenarios</p>
              </div>
              <div className="benefit-item">
                <span className="benefit-icon">🎯</span>
                <h3>Customized Content</h3>
                <p>Tailored to your team's needs</p>
              </div>
              <div className="benefit-item">
                <span className="benefit-icon">📈</span>
                <h3>Ongoing Support</h3>
                <p>Post-training consultation available</p>
              </div>
            </div>
          </div>

          <div className="training-pricing">
            <h2 className="pixel-text">Training Packages</h2>
            <div className="pricing-grid">
              <motion.div
                className="pricing-card"
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="pricing-header">
                  <h3>Single Session</h3>
                  <div className="price">$699</div>
                  <p>3-Hour In-Person Intensive Training</p>
                </div>
                <div className="pricing-features">
                  <ul>
                    <li>Up to 12 team members</li>
                    <li>Choose Session 1 or 2</li>
                    <li>Interactive workshops</li>
                    <li>Practice exercises</li>
                    <li>Session notes</li>
                    <li>Training materials</li>
                  </ul>
                </div>
                <button
                  onClick={() => {
                    window.location.href =
                      "https://calendly.com/jon-t3ch/half-day";
                  }}
                  className="pixel-button"
                >
                  <span className="button-text">BOOK SINGLE SESSION</span>
                  <span className="button-border"></span>
                </button>
              </motion.div>

              <motion.div
                className="pricing-card featured"
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="pricing-header">
                  <h3>Complete Program</h3>
                  <div className="price">$1,199</div>
                  <p>6-Hour In-Person Complete Training</p>
                </div>
                <div className="pricing-features">
                  <ul>
                    <li>Up to 12 team members</li>
                    <li>Both Sessions included</li>
                    <li>Extended workshops</li>
                    <li>Implementation planning</li>
                    <li>30-day support</li>
                    <li>All training materials</li>
                  </ul>
                </div>
                <button
                  onClick={() => {
                    window.location.href =
                      "https://calendly.com/jon-t3ch/full-day";
                  }}
                  className="pixel-button"
                >
                  <span className="button-text">BOOK COMPLETE PROGRAM</span>
                  <span className="button-border"></span>
                </button>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AITraining;
