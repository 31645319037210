import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaGamepad, FaGhost, FaDice, FaPuzzlePiece } from "react-icons/fa";
import Training from "./Training";
import { Link } from "react-router-dom";
import ArrowGame from "./ArrowGame";

function Home() {
  const [score, setScore] = useState(0);
  const game = ArrowGame({ onScoreChange: setScore });
  return (
    <>
      <header className="App-header">
        <div className="floating-icons">
          <FaGamepad className="floating-icon" style={{ "--delay": "0s" }} />
          <FaGhost className="floating-icon" style={{ "--delay": "2s" }} />
          <FaDice className="floating-icon" style={{ "--delay": "4s" }} />
          <FaPuzzlePiece
            className="floating-icon"
            style={{ "--delay": "6s" }}
          />
        </div>
        <div className="header-content">
          <div className="hero-content">
            <img
              src="/assets/qiiver_logo.png"
              alt="Qiiver Logo"
              className="hero-logo"
            />
            <h1>
              <span>Retro Problems</span>
              <br />
              <span>Modern Solutions</span>
            </h1>
            <p>
              Qiiver exists to bring a fresh perspective to
              <br />
              existing software challenges through innovative
              <br />
              solutions and modern technology
            </p>
          </div>
        </div>
      </header>

      <section className="interactive-services">
        <div className="services-scroll-container">
          <div className="services-track">
            {[...Array(2)]
              .map(() => [
                {
                  title: "Digital Transformation",
                  description:
                    "Transform your traditional business processes into efficient digital workflows",
                  icon: "✨",
                },
                {
                  title: "Custom Software",
                  description:
                    "Tailored solutions that perfectly fit your unique business needs",
                  icon: "💼",
                },
                {
                  title: "Website Enhancement",
                  description:
                    "Modernize your online presence and improve customer engagement",
                  icon: "🌟",
                },
                {
                  title: "Business Automation",
                  description:
                    "Reduce manual work and costs with smart automation solutions",
                  icon: "⚡",
                },
                {
                  title: "Data Solutions",
                  description:
                    "Turn your business data into actionable insights and reports",
                  icon: "📊",
                },
                {
                  title: "Tech Consulting",
                  description:
                    "Expert guidance on making the right technology decisions",
                  icon: "🤝",
                },
                {
                  title: "AI Training",
                  description:
                    "Comprehensive AI training programs to empower your team with cutting-edge capabilities",
                  icon: "🎓",
                },
                {
                  title: "Innovation Sprint",
                  description:
                    "Rapid prototyping and innovation workshops to accelerate your digital initiatives",
                  icon: "🚀",
                },
                {
                  title: "CTO Matchmaking",
                  description:
                    "I'll introduce you to my network of friends to find the perfect match or evaluate current candidates",
                  icon: "🤓",
                },
              ])
              .flat()
              .map((service, index) => (
                <motion.div
                  key={index}
                  className="service-card"
                  whileHover={{
                    scale: 1.05,
                    rotate: [-1, 1, -1, 0],
                    transition: { duration: 0.2 },
                  }}
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    className="service-icon"
                    style={{
                      marginTop: "0.5rem",
                    }}
                  >
                    {service.icon}
                  </div>
                  <div style={{ marginLeft: "1rem", textAlign: "left" }}>
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                  </div>
                </motion.div>
              ))}
          </div>
        </div>
        <div className="training-cta">
          <motion.div
            className="cta-card"
            whileHover={{ scale: 1.02 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <h3>AI Training for Your Team</h3>
            <div className="cta-content">
              <div className="cta-features">
                <div className="cta-feature">
                  <span className="feature-icon">🎯</span>
                  <p>Tailored for your industry</p>
                </div>
                <div className="cta-feature">
                  <span className="feature-icon">👥</span>
                  <p>Expert-led sessions</p>
                </div>
                <div className="cta-feature">
                  <span className="feature-icon">💡</span>
                  <p>Hands-on workshops</p>
                </div>
              </div>
              <div className="cta-text">
                <p>
                  Transform your team into AI champions with our comprehensive
                  training programs. From fundamentals to advanced applications,
                  we'll guide your journey into the future of technology.
                </p>
                <Link to="/ai-training" className="pixel-button">
                  <span className="button-text">
                    EXPLORE AI TRAINING PROGRAMS
                  </span>
                  <span className="button-border"></span>
                </Link>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="ai-section">
        <div className="content-container">
          <h2>
            <span className="glitch" data-text="AI Development">
              AI Development
            </span>
          </h2>
          <div className="ai-grid">
            <div className="ai-card">
              <div className="ai-card-inner">
                <div className="ai-card-front">
                  <h3>🤖 AI Agents</h3>
                  <p>Tap to learn more</p>
                </div>
                <div className="ai-card-back">
                  <p>
                    Custom AI agents that automate tasks, interact with users,
                    and streamline your business processes. Perfect for customer
                    service and workflow automation.
                  </p>
                </div>
              </div>
            </div>
            <div className="ai-card">
              <div className="ai-card-inner">
                <div className="ai-card-front">
                  <h3>📚 RAG Systems</h3>
                  <p>Tap to learn more</p>
                </div>
                <div className="ai-card-back">
                  <p>
                    Retrieval Augmented Generation systems that combine your
                    business knowledge with AI capabilities. Ideal for
                    documentation, support, and knowledge management.
                  </p>
                </div>
              </div>
            </div>
            <div className="ai-card">
              <div className="ai-card-inner">
                <div className="ai-card-front">
                  <h3>✨ Prompt Engineering</h3>
                  <p>Tap to learn more</p>
                </div>
                <div className="ai-card-back">
                  <p>
                    Expert prompt design and optimization for LLMs. We help you
                    craft effective prompts that deliver consistent,
                    high-quality results for your AI applications.
                  </p>
                </div>
              </div>
            </div>
            <div className="ai-card">
              <div className="ai-card-inner">
                <div className="ai-card-front">
                  <h3>🎓 Training</h3>
                  <p>Tap to learn more</p>
                </div>
                <div className="ai-card-back">
                  <p>
                    Comprehensive AI training programs for your team. Learn how
                    to effectively implement and manage AI solutions in your
                    business environment.
                  </p>
                </div>
              </div>
            </div>
            <div className="ai-card">
              <div className="ai-card-inner">
                <div className="ai-card-front">
                  <h3>📱 AI Automation & Apps</h3>
                  <p>Tap to learn more</p>
                </div>
                <div className="ai-card-back">
                  <p>
                    Custom AI-powered applications built for your specific
                    needs. From mobile apps to web platforms, we create
                    intuitive solutions that leverage AI capabilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="ai-card">
              <div className="ai-card-inner">
                <div className="ai-card-front">
                  <h3>🔄 AI Integration</h3>
                  <p>Tap to learn more</p>
                </div>
                <div className="ai-card-back">
                  <p>
                    Seamlessly integrate AI capabilities into your existing
                    systems. We ensure smooth implementation with your current
                    infrastructure and workflows.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ai-features">
            <div className="feature">
              <div className="feature-icon">💼</div>
              <h4>Business-Focused</h4>
              <p>Practical solutions for real needs</p>
            </div>
            <div className="feature">
              <div className="feature-icon">🔒</div>
              <h4>Secure</h4>
              <p>Enterprise-grade security</p>
            </div>
            <div className="feature">
              <div className="feature-icon">📈</div>
              <h4>Scalable</h4>
              <p>Grows with your business</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mission-section">
        <div className="mission-content">
          <div className="mission-text">
            <h2>Our Mission</h2>
            <p>
              At Qiiver, we're on a mission to bridge the gap between
              traditional business challenges and cutting-edge technology
              solutions. We believe that every business deserves access to
              modern, efficient, and scalable software solutions that drive real
              results.
            </p>
            <p>
              Through our innovative approach and deep technical expertise, we
              transform complex problems into elegant, user-friendly solutions
              that help businesses thrive in the digital age. Our commitment to
              excellence and continuous improvement ensures that we stay ahead
              of technological trends while delivering practical value to our
              clients.
            </p>
          </div>
          <div className="mission-image">
            <div className="rocket-icon">🚀</div>
          </div>
        </div>
      </section>

      <section className="download-section">
        <div className="content-container">
          <h2>
            <span className="pixel-text">Example UI Development</span>
          </h2>
          <div className="download-content">
            <div className="download-card">
              <div className="pixel-art-icon">
                <span className="pixel-icon">⬇️</span>
              </div>
              <div className="download-text">
                <p>
                  Level up your project with our comprehensive collection of
                  software examples! Unlock modern solutions to complex problems
                  with our development kit.
                </p>
                <a
                  href="https://mcusercontent.com/eb611aa7e3c209fb89b8f546f/files/57feac30-88a5-12ef-3542-cad447446acc/Software_Examples.pdf"
                  className="download-button"
                >
                  <span className="button-text">DOWNLOAD UI EXAMPLE KIT</span>
                  <span className="button-border"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="game-section">
        <div className="content-container">
          <h2 className="pixel-text">Qiiver Archery</h2>
          <p>Test your aim in this archery mini-game!</p>
          <div className="game-container">
            <div className="game-score">
              <span>Score: {game.score}</span>
              <span>Arrows: {game.arrowsShot}</span>
              <span>Time: {game.timeLeft}s</span>
            </div>
            <div className="game-area">
              {game.targets.map((target) => (
                <motion.div
                  key={target.id}
                  className="target"
                  style={{
                    left: target.x + "px",
                    top: target.y + "px",
                  }}
                />
              ))}
              {game.projectiles.map((arrow, index) => (
                <motion.div
                  key={index}
                  className="arrow"
                  style={{
                    left: arrow.x + "px",
                    top: arrow.y + "px",
                    transform: `rotate(${arrow.angle}rad)`,
                  }}
                />
              ))}
              <motion.div
                className="bow"
                style={{
                  left: game.arrow.x + "px",
                  top: game.arrow.y + "px",
                  transform: `rotate(${game.arrow.angle}rad)`,
                }}
              />
            </div>
            <div className="game-status">
              {game.gameOver
                ? `Time's up! Final score: ${game.score} (${game.accuracy}% accuracy)`
                : game.isPlaying
                ? "Click to shoot!"
                : "Press START to play!"}
            </div>
            <div className="game-controls">
              <button
                className="pixel-button"
                onClick={game.isPlaying ? game.resetGame : game.startGame}
              >
                <span className="button-text">
                  {game.isPlaying ? "RESET GAME" : "START GAME"}
                </span>
                <span className="button-border"></span>
              </button>
              <div className="control-hint">
                Use mouse to aim and click to shoot
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <div className="content-container">
          <h2 className="contact-title">
            <span className="pixel-text">Want to Level Up?</span>
          </h2>
          <p className="contact-subtitle">
            Let's team up and create something amazing together!
            <br />
            Press START to begin your journey.
          </p>
          <form className="contact-form">
            <button
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "https://calendly.com/jon-t3ch/30min";
              }}
              type="submit"
              className="pixel-button"
            >
              <span className="button-text">PRESS START</span>
              <span className="button-border"></span>
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default Home;
